import React from "react";
import Footer from "../../../../components/Footer/Footer";
import SupportLeftNav from "../../../../components/TwoColumnLeftNav/SupportLeftNav";
import PagingController from "../../../../components/PagingController/PagingController";

const Documents = () => {
	// useTranslations is aware of the global context (and therefore also "locale")
	// so it'll automatically give back the right translations

	return (
		<>
			<article className="company-info two-column-left-nav">
				<SupportLeftNav />
				<section className="main-content">
					<h2 className={"large bold"}>温度って何だろう</h2>
					<div className="content-container">
						<div className="office_inner">
							<div className="flex-container">
								<div className="temp01_flex1 temp01_flex1_title">
									<div className="title_boder">
										<p className="medium temp01_font_title">
											物体が温かい、または冷たいということを数値で表したものが温度です。
										</p>
									</div>
									<p className="medium">
										火に手をかざすと、私たちは温かいと感じます。また、水に手を入れると冷たいと感じます。
										ところが、この温かい、冷たいという感覚は人により微妙にちがいます。しかも同じ温度の水でも、暑い日と寒い日では、まるでちがう感じがするものです。
										そこで、どのような条件下であっても、誰もが共通のことばで温かさ、冷たさを伝えあうために、数値で表すことが考えられました。
										温かい、冷たいという感覚的な表現のかわりに、数値で表現したのが温度なのです。現在使われている温度の単位で最も古いのはｆ（華氏温度）です。
										１７２０年頃、ファーレンファイト（ドイツ）の設定したこの単位は、今もアメリカ、カナダなどの国で使われています。
										つづいて１７２４年頃、スウェーデンの天文学者セルシウスがファーレンハイトとは異なる単位をもつ温度計をつくりました。
										これが、℃（摂氏温度）です。現在日本をはじめ多くの国々ではこの単位が使われています。
										このほか、物理学の分野ではイギリスの物理学者ケルビンの定めたK（
										<a
											href="/support/documents/about-temperature/temp10#absolute_temperature"
											className="temp01_absolutetemperature"
										>
											絶対温度*
										</a>
										）が温度の単位として使われています。
									</p>
									<p className="medium">
										<a
											href="/support/documents/about-temperature/temp10#absolute_temperature"
											className="temp01_absolutetemperature"
										>
											*絶対温度（温度計一口メモ）
										</a>
									</p>
								</div>

								<div className="temp01_flex2 temp01_flex2_title2">
									<div className="title_boder2">
										<p className="medium temp01_font_title">
											温度と熱エネルギー。
										</p>
									</div>
									<p className="medium">
										すべての物体は、原子や分子によって構成されています。物体が熱くなるのは、この原子や分子の運動が活発になるからです（図１）。一方、冷たくなるのは原子や分子の運動が不活発になるからです（図２）。この運動を物理学では熱エネルギーと呼んでいます。つまり、物体の温度は、熱エネルギーの状態によって高くなったり、低くなったりしているのです。そして、すべての物体（絶対温度０度以上の物体）は、熱エネルギーに応じた視光線や赤外線を放射していることが知られています。温度は熱エネルギーの状態を数値化したものです。
									</p>
								</div>
							</div>

							<div className="flex-container">
								<div className="temp01_flex1 temp01_image">
									<p className="medium temp01_figure_explanation">
										図1 運動が活発な状態
									</p>
									<img
										className="temp01_chart"
										src="/asset/chart01.gif"
										height="300"
										width="300"
										alt="熱エネルギーの運動が活発な状態"
									/>
								</div>

								<div className="temp01_flex2 temp01_image">
									<p className="medium temp01_figure_explanation">
										図2 運動が不活発な状態
									</p>
									<img
										className="temp01_chart"
										src="/asset/chart02.gif"
										height="300"
										width="300"
										alt="熱エネルギーの運動が不活発な状態"
									/>
								</div>
							</div>
							<div className="temp01_energy_value">
								<p className="medium temp01_font_title temp01_font_title_border">
									温度は熱エネルギーの状態を数値化したものです。
								</p>
							</div>
						</div>
					</div>
					<PagingController
						current={1}
						next={"/support/documents/about-temperature/temp02"}
						prev={"#"}
					/>
				</section>
			</article>
			<Footer />
		</>
	);
};

export default Documents;
